export default {
  'Initial conversation date': 'Inicio de conversación',
  'Assigned user': 'Usuario asignado',
  'No user assigned': 'Sin usuario asignado',
  Change: 'Cambiar',
  'Change owner': 'Cambiar propietario',
  'In property of': 'En propiedad de',
  'Type Chat (Messenger, Whatsapp, etc)': 'Tipo Chat (Messenger, Whatsapp, etc)',
  'Time window error': 'Mensaje no enviado. Supera límite de 24 horas.',
  'New field name': 'Nombre del nuevo campo',
  'Mark as read': 'Marcar como leído',
  'Last message not sent': 'Último mensaje no enviado',
  'Sequence in progress': 'Secuencia en proceso',
  'Mark as unread': 'Marcar como no leído',
  'Information fields': 'Campos de información',
  'Add or modify information fields that users can fill in about customers':
    'Agregue o modifique los campos de información que pueden completar los usuarios acerca de los clientes.',
  'Add or modify information field': 'Agregue o modifique los campos de información',
  'You can drag the fields to sort them': 'Puede arrastrar los campos para ordenarlos.',
  Actives: 'Activos',
  Inactives: 'Inactivos',
  'Manage fields': 'Gestionar campos',
  'Edit custom field': 'Editar campo',
  'Deactivate custom field': 'Desactivar campo',
  'Activate custom field': 'Activar campo',
  'Delete custom field': 'Eliminar campo',
  Delete_custom_help: '¿Estás seguro que quieres eliminar este campo? Esta acción no es reversible.',
  'Delete custom field option': 'Eliminar opción',
  Delete_custom_option_help: '¿Estás seguro que quieres eliminar esta opción? Esta acción no es reversible.',
  Sequence: 'Secuencia',
  Started: 'iniciada',
  'No tags have been assigned yet': 'Aún no se han asignado etiquetas',
  'This channel is unlinked': 'Este canal está desvinculado.',
  'Go to integrations': 'Ir a integraciones',
  'Please select a conversation': 'Por favor seleccione una conversación',
  'Delete note': 'Eliminar nota',
  'Delete note help': '¿Estás seguro que quieres eliminar esta nota?',
  'Use template': 'Usar plantilla',
  'Use sequence': 'Usar secuencia',
  'Launch sequence': 'Iniciar secuencia',
  'Right now': 'Ahora',
  Schedule: 'Programar',
  Begin: 'Iniciar',
  'This message will be sent in': 'Se enviará este paso si no hay respuesta en',
  'Not assigned': '-',
  'Stop sequence': 'Detener secuencia',
  Stop_sequence_help: '¿Estás seguro que quieres detener esta secuecia? Esta acción no es reversible.',
  'Begin sequence': 'Empezar secuencia en',
  hour: 'hora',
  hours: 'horas',
  minute: 'minuto',
  minutes: 'minutos',
  'Unread messages': 'Más mensajes sin leer',
  'Add a category to start building your board': 'Agregue una categoría para comenzar a armar su tablero.',
  missing_permissions: 'Permisos faltantes',
  unauthorized_application: 'Mensaje no enviado. El canal se encuentra desvinculado.',
  unauthorized_application_help:
    'Si crees que este canal debe estar vinculado, por favor comunícate con el administrador de tu empresa para que reintegre este canal desde la página de Integraciones.',
  time_window_error: 'Mensaje no enviado. Se han superado las 24hs.',
  time_window_error_help:
    'Una vez que un cliente haya iniciado o respondido a un mensaje en Whatsapp, el negocio tiene hasta 24 horas para responderle. Después de 24 horas desde el último mensaje del cliente, el negocio no puede enviarle más mensajes. (De acuerdo a las políticas de Whatsapp).',
  time_window_one_week_error: 'Mensaje no enviado. Se han superado los 7 dias.',
  time_window_one_week_error_help:
    'Una vez que un cliente haya iniciado o respondido a un mensaje en Messenger o Instagram, el negocio tiene como máximo 7 días para responderle. (De acuerdo a las políticas de Facebook Messenger).',
  time_window_sequence_failure: 'La secuencia falló porque se acabó la ventana de 24hs.',
  time_window_sequence_failure_help:
    'Una vez que un cliente haya iniciado o respondido a un mensaje en Messenger o Whatsapp, el negocio tiene hasta 24 horas para responderle. Después de 24 horas desde el último mensaje del cliente, el negocio no puede enviarle más mensajes. (De acuerdo a las políticas de Facebook Messenger y Whatsapp)',
  max_whatsap: 'La cuenta ha llegado al límite de mensajes de WhatsApp.',
  max_whatsapp_help:
    'La cuenta seguirá recibiendo mensajes pero no se podrán responder hasta que comience el nuevo período o se haga el cambio de plan.',
  'The channel is unlinked and you will not be able to send messages by the same':
    'El canal se encuentra desvinculado y no podrá enviar mensajes por el mismo.',
  unknown_message_error: 'Mensaje no enviado. Por favor intente nuevamente.',
  unknown_message_received_error: 'Problemas recibidos por WhatsApp.',
  whatsapp_experimental_error: 'Mensaje no enviado.',
  whatsapp_experimental_error_help:
    'Este número es parte de un experimento de Facebook y ya no permite recibir plantillas pre aprobadas. Por favor contactar a esta persona por otro medio y pedirle que te escriba por WhatsApp para poder continuar con la conversación.',
  facebook_experiment: 'experimento de Facebook',
  Attachment: 'Adjunto',
  Attachments: 'Adjuntos',
  CustomClients_file_too_big: 'El archivo pesa más de 10GB y no puede ser enviado. Por favor bajar su peso e intentar nuevamente.',
  Instagram_file_too_big: 'El archivo pesa más de 4GB y no puede ser enviado. Por favor bajar su peso e intentar nuevamente.',
  Whatsapp_file_too_big: 'El archivo pesa más de 16MB y no puede ser enviado. Por favor bajar su peso e intentar nuevamente.',
  Facebook_file_too_big: 'El archivo pesa más de 25MB y no puede ser enviado. Por favor bajar su peso e intentar nuevamente.',
  'Invalid file type': 'Tipo de archivo inválido para ',
  'File too big': 'Archivo muy grande',
  'This account used all available WhatsApp pre-approved templates. Please talk to your admin to reload':
    'Esta cuenta usó todas las plantillas preaprobadas en WhatsApp disponibles. Por favor habla con tu admin para recargar.',
  'Max characters exceeded': 'Máximo de caracteres superado.',
  'Use pre-approved templates': 'Usar plantilla preaprobada.',
  'Pre-approved template popper help':
    'Las plantillas preaprobadas pueden ser usadas para enviar mensajes en Whatsapp una vez finalizado el límite de 24 horas desde el último mensaje del cliente. De acuerdo a las políticas de Whatsapp.',
  InformationLastMessageError: 'Último mensaje no enviado',
  'You have not added any notes to this conversation yet': 'Aún no ha agregado notas a esta conversación.',
  'Loading messages': 'Cargando conversaciones',
  'Manual conversation': 'Conversación manual',
  'Choose one of the following options to start a conversation': 'Elija una de las siguientes opciones para comenzar una conversación.',
  'New WhatsApp conversation': 'Nueva conversación de Whatsapp',
  'Send an e-mail': 'Enviar correo electrónico',
  'Call contact': 'Llamar al contacto',
  wrongNumberErrorHelp: '¡Ha ocurrido un error! El número ingresado no es correcto y no se ha podido enviar el mensaje.',
  'Change number': 'Cambiar número',
  sticker_error: 'Alguien te envió un sticker pero Uniboxi no lo puede mostrar.',
  ephemeral_error:
    'Alguien te envió un mensaje que desaparece después de un tiempo. Lamentablemente no lo podemos mostrar por restricciones de WhatsApp.',
  'You have entered an incorrect WhatsApp number': 'Ha ingresado un número de Whatsapp incorrecto o inválido.',
  unsupported_instagram_error:
    'Alguien te envió un mensaje con un elemento que Uniboxi no puede mostrar por restricciones del API de Instagram.',
  unsupported_whatsapp_error:
    'Alguien te envió un mensaje con un elemento que Uniboxi no puede mostrar por restricciones del API de Whatsapp.',
  number_already_contacted:
    'No es posible enviar el mensaje de WhatsApp ya que el número está asociado a una conversación anterior. El usuario asignado de esa conversación es: {{assignedUserName}}. Si tu eres el propietario de esa conversación, búscala en el tablero principal o en Conversaciones eliminadas.',
  'Message read': 'Mensaje leido',
  'Message sent': 'Mensaje enviado',
  'Sending message': 'Enviando mensaje',
  'Message delivered': 'Mensaje entragado',
  'Has sended type': 'Ha enviado un {{type}}',
  empty_fields_template: 'Para usar esta plantilla debes tener los siguientes campos con información: ',
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'Correo electrónico',
  number: 'Teléfono',
  assignedUserName: 'Nombre del usuario asignado',
  assignedUserEmail: 'Correo electrónico del usuario asignado',
  assignedUserPhone: 'Teléfono del usuario asignado',
  '<firstName>': '<Nombre>',
  '<lastName>': '<Apellido>',
  '<email>': '<Correo electrónico>',
  '<number>': '<Teléfono>',
  '<assignedUserName>': '<Nombre del usuario asignado>',
  '<assignedUserEmail>': '<Correo electrónico del usuario asignado>',
  '<assignedUserPhone>': '<Teléfono del usuario asignado>',
  empty_fields_secuence: 'Para usar esta secuencia, por favor completa los siguientes campos del contacto: ',
  invalid_attachment_secuence: 'Esta secuencia contiene un formato de adjunto inválido para {{kind}}.',
  reply_to: 'Respondió a tu historia',
  story_mention: 'Te mencionó en su historia',
  'This message has been deleted.': 'Este mensaje ha sido eliminado.',
  'Pre-approved templates can not be modified. If you need a different template, talk to your administrator.':
    'Las plantillas preaprobadas no se pueden modificar. Si necesitas una plantilla distinta, habla con tu administrador.',
  'Invalid Date Format': 'Formato de fecha inválido.',
  'Recording audio...': 'Grabando audio...',
  'Template Missing': 'Esta plantilla todavía no está disponible. Por favor, esperar 30 minutos y volver a intentar',
  'Give like': 'Dar "Me gusta"',
  'Please allow the explorer to use your microphone.': 'Por favor permita al navegador utilizar el micrófono.',
  'To record voice messages, you must allow Uniboxi to access the microphone in Chrome. To do this, click the icon':
    'Para grabar mensajes de voz, debes permitir a Uniboxi el acceso al micrófono en Chrome. Para esto, haz clic en el icono',
  'in the URL bar and choose "Always allow microphone access to app.uniboxi.com".':
    'en la barra de URL y elige "Siempre permitir el acceso a micrófono a app.uniboxi.com".',
  Download: 'Descargar',
  'Copy text': 'Copiar texto',
  phoneChangedNewLead: ', para seguir conversando haga clic aquí ',
  Image: 'Imagen',
  Video: 'Video',
  Audio: 'Audio',
  File: 'Archivo',
  'v-card': 'Tarjeta de contacto',
  Story: 'Historia',
  'Reply cannot be audios or attachments.': 'Las respuestas no pueden ser audios o adjuntos.',
  Reply: 'Responder',
  'This contact belongs to another user of the account. To be able to send a message to this contact, you must be the assigned user of this card.':
    'Este contacto pertenece a otro usuario de la cuenta. Para poder enviarle un mensaje debes ser el usuario asignado de esta tarjeta.',
};
