import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCcVisa,
  faFacebookMessenger,
  faWhatsapp,
  faInstagram,
  faFacebookSquare,
  faChrome,
} from '@fortawesome/free-brands-svg-icons';
import {
  faUsers,
  faNetworkWired,
  faChartArea,
  faGift,
  faStar,
  faPencil as faPencilLight,
  faTrash,
  faExclamationCircle as faExclamationCircleLight,
  faArrowAltToBottom,
  faFileCsv,
  faFileExcel,
  faPlus as faPlusLight,
  faMinusCircle as faMinusCircleLight,
  faCheckCircle as faCheckCircleLight,
  faCopy as faCopyLight,
  faEdit as faEditLight,
  faUser as faUserLight,
  faSmileBeam as faSmileBeamLight,
  faFolderOpen,
} from '@fortawesome/pro-light-svg-icons';
import {
  faPencil,
  faCommentAltSmile,
  faCommentsAlt,
  faNewspaper,
  faTrash as faTrashRegular,
  faEdit,
  faChevronRight,
  faChevronLeft,
  faBars,
  faQuestionCircle,
  faUser,
  faUsers as faUsersRegular,
  faUsersCog,
  faSignOut,
  faCamera,
  faSave,
  faEnvelope,
  faLock,
  faBuilding,
  faGlobe,
  faPhoneAlt,
  faMapPin,
  faGlobeStand,
  faCreditCard,
  faShieldAlt,
  faCalendar,
  faPlus,
  faCity,
  faMailbox,
  faCheck,
  faFileInvoiceDollar,
  faMinus,
  faTimes,
  faBan,
  faChevronUp,
  faChevronDown,
  faUserPlus,
  faGripLinesVertical,
  faLink,
  faFile,
  faListOl,
  faClipboardList,
  faPaperclip,
  faThumbsUp,
  faExclamationCircle,
  faExclamationTriangle,
  faEnvelopeOpen,
  faThumbsDown,
  faEllipsisV,
  faSearch,
  faEye,
  faEyeSlash,
  faInbox,
  faTrashAlt,
  faFolder,
  faListAlt,
  faMale,
  faHandRock,
  faFolderPlus,
  faTag,
  faUndoAlt,
  faTimesCircle,
  faTimesHexagon,
  faSmile,
  faStar as faStarRegular,
  faFilter,
  faMinusCircle,
  faUnlink,
  faBell,
  faBellOn,
  faArrowLeft,
  faMoneyCheckAlt,
  faFileCertificate,
  faMap,
  faIdCard,
  faPhone,
  faCheckCircle,
  faStopCircle,
  faArrowUp,
  faClone,
  faCommentAltLines,
  faGlobeAmericas,
  faCalendarExclamation,
  faCalendarAlt,
  faCommentAltExclamation,
  faUserEdit,
  faClock,
  faCircle,
  faChevronCircleRight,
  faSync,
  faCopy as faCopyRegular,
  faCode,
  faUserHeadset,
  faMicrophone,
  faPlayCircle,
  faStopCircle as faStopCircleRegular,
  faClipboardCheck,
  faFileSpreadsheet,
  faFileDownload,
  faExchange,
  faArrowAltToBottom as faArrowAltToBottomRegular,
  faFilePdf,
  faFileTimes,
  faFilm,
  faKey,
  faSnooze,
  faSignOutAlt,
  faReply,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretDown,
  faCaretUp,
  faChevronRight as faChevronRightSolid,
  faChevronLeft as faChevronLeftSolid,
  faCircle as faCircleSolid,
  faCheckCircle as faCheckCircleSolid,
  faBan as faBanSolid,
  faStar as faStarSolid,
  faTag as faTagSolid,
  faUserCircle as faUserCircleSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faSync as faSyncSolid,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faMicrophone as faMicrophoneSolid,
  faPlay,
  faPause,
  faHourglass,
  faDownload,
  faExclamationCircle as faExclamationCircleSolid,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  /* brands */
  faCcVisa,
  faFacebookMessenger,
  faWhatsapp,
  faInstagram,
  faFacebookSquare,
  faChrome,

  /* pro light */
  faUsers,
  faCopyLight,
  faNetworkWired,
  faChartArea,
  faGift,
  faStar,
  faPencilLight,
  faTrash,
  faExclamationCircleLight,
  faArrowAltToBottom,
  faFileCsv,
  faFileExcel,
  faPlusLight,
  faMinusCircleLight,
  faCheckCircleLight,
  faEditLight,
  faUserLight,
  faFolderOpen,
  faSmileBeamLight,

  /* pro regular */
  faPencil,
  faCode,
  faCommentAltSmile,
  faCopyRegular,
  faSync,
  faSignOutAlt,
  faCommentsAlt,
  faFileDownload,
  faNewspaper,
  faTrashRegular,
  faEdit,
  faChevronRight,
  faChevronLeft,
  faKey,
  faBars,
  faQuestionCircle,
  faUser,
  faUsersRegular,
  faUsersCog,
  faSignOut,
  faCamera,
  faSave,
  faEnvelope,
  faLock,
  faArrowAltToBottom,
  faBuilding,
  faGlobe,
  faPhoneAlt,
  faMapPin,
  faGlobeStand,
  faCreditCard,
  faShieldAlt,
  faCalendar,
  faPlus,
  faCity,
  faMailbox,
  faCheck,
  faFileInvoiceDollar,
  faMinus,
  faTimes,
  faTimesCircle,
  faBan,
  faChevronUp,
  faChevronDown,
  faUserPlus,
  faGripLinesVertical,
  faLink,
  faFile,
  faListOl,
  faClipboardList,
  faPaperclip,
  faThumbsUp,
  faExclamationCircle,
  faExclamationTriangle,
  faEnvelopeOpen,
  faThumbsDown,
  faEllipsisV,
  faSearch,
  faEye,
  faEyeSlash,
  faFileSpreadsheet,
  faInbox,
  faTrashAlt,
  faFolder,
  faListAlt,
  faMale,
  faHandRock,
  faFolderPlus,
  faTag,
  faUndoAlt,
  faTimesHexagon,
  faSmile,
  faStarRegular,
  faFilter,
  faMinusCircle,
  faUnlink,
  faBell,
  faBellOn,
  faSnooze,
  faBan,
  faArrowLeft,
  faMoneyCheckAlt,
  faFileCertificate,
  faMap,
  faIdCard,
  faPhone,
  faCheckCircle,
  faStopCircle,
  faArrowUp,
  faClone,
  faCommentAltLines,
  faGlobeAmericas,
  faCalendarExclamation,
  faCalendarAlt,
  faUserEdit,
  faClock,
  faCircle,
  faChevronCircleRight,
  faMicrophone,
  faPlayCircle,
  faStopCircleRegular,
  faUserHeadset,
  faClipboardCheck,
  faExchange,
  faArrowAltToBottomRegular,
  faFilePdf,
  faFileTimes,
  faFilm,
  faReply,

  /* pro solid */
  faExclamationCircleSolid,
  faQuestionCircleSolid,
  faCaretDown,
  faSyncSolid,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faCaretUp,
  faUserCircleSolid,
  faChevronRightSolid,
  faChevronLeftSolid,
  faCircleSolid,
  faCheckCircleSolid,
  faBanSolid,
  faStarSolid,
  faTagSolid,
  faCommentAltExclamation,
  faMicrophoneSolid,
  faPlay,
  faPause,
  faHourglass,
  faDownload,

);
