import esIntegrations from './esIntegrations';
import esLogin from './esLogin';
import esTopbar from './esTopbar';
import esDrawer from './esDrawer';
import esAccount from './esAccount';
import esEllipsisPopover from './esEllipsisPopover';
import esTemplates from './esTemplates';
import esRules from './esRules';
import esChat from './esChat';
import esSequences from './esSequences';
import esFilter from './esFilter';
import esDashboard from './esDashboard';
import esNotifications from './esNotifications';
import esAutomation from './esAutomation';
import esReports from './esReports';
import esTimezones from './esTimezones';
import esTools from './esTools';

export default {
  es: {
    translation: {
      Close: 'Cerrar',
      hello: 'hello',
      Integrations: 'Integraciones',
      Link: 'Vincular',
      Unlink: 'Desvincular',
      'Use Chrome': 'Utiliza Chrome',
      DownloadChrome_Text: 'Hemos detectado que no estás usando el navegador de Chrome. Para asegurar la mejor experiencia en Uniboxi, te recomendamos cambiarte de navegador a Chrome o descargarlo en caso que no lo tengas.',
      'Download Chrome': 'Descargar Chrome',
      'Add tag': 'Agregar etiqueta',
      'Empty name error': 'El nombre no puede estar vacío',
      'New tag name': 'Nombre de la nueva etiqueta',
      'Add new conversation': 'Agregar nueva conversación',
      'Add contact': 'Añadir contacto',
      'Send Whatsapp': 'Enviar Whatsapp',
      'Whatsapp number': 'Número de WhatsApp',
      'New whatsapp message': 'Nuevo mensaje de Whatsapp',
      'New whatsapp message help':
        'Escriba el número de WhatsApp con el código de país. No es necesario agregar "+" al número.',
      'Edit category': 'Editar nombre de categoría',
      New: 'Nuevo',
      Board: 'Tablero',
      List: 'Lista',
      Filter: 'Filtrar',
      Edit: 'Editar',
      Notes: 'Notas',
      'Add Note': 'Agregar nota',
      Send: 'Enviar',
      'Type a message...': 'Escriba su mensaje...',
      'Active sequence...': 'Secuencia activa...',
      Inbox: 'Inbox',
      Inbox_help: 'Arrastre las conversaciones a la categoría que desee',
      'No conversations': 'Arrastre conversaciones a esta categoría',
      'No conversations inbox': 'No tienes nuevas conversaciones',
      'Add Category': 'Agregar categoría',
      'Assign category': 'Asignar categoría',
      'Category Name': 'Nombre de la categoría',
      'Delete category': 'Eliminar categoría',
      'Edit tag': 'Editar etiqueta',
      'Choose a color': 'Elige un color',
      'Deleted conversations': 'Conversaciones eliminadas',
      'New name': 'Nombre nuevo',
      Confirm: 'Confirmar',
      Accept: 'Aceptar',
      Cancel: 'Cancelar',
      'Delete conversation': 'Eliminar conversacion',
      'Delete tag': 'Eliminar etiqueta',
      Delete_tag_help1: '¿Está seguro que desea eliminar esta etiqueta?',
      Delete_help1: '¿Está seguro que desea eliminar esta conversación?',
      Delete_category_help1: '¿Está seguro que desea eliminar esta categoría?',
      Delete_category_help2:
        'No es posible eliminar categorías con clientes. Por favor elimina o mueve todas las conversaciones de la categoría antes de borrarla.',
      Delete_help2: 'Podrá encontrarla en la sección de conversaciones eliminadas.',
      Restore_help1: '¿Desea restaurar esta conversacion?',
      Restore_help2: 'Podrá encontrarla nuevamente en su inbox.',
      Remove_help1: '¿Está seguro que desea eliminar esta conversación definitivamente?',
      Remove_help2: 'No podrá recuperarla nuevamente.',
      'Assign users': 'Asignar usuarios',
      'Assign rules': 'Reglas de asignar mensajes',
      'Write your note': 'Escriba su nota.',
      Delete: 'Eliminar',
      'Add field': 'Agregar campo',
      'Insert file': 'Adjuntar archivo',
      'Insert variable': 'Insertar variable',
      'Go to templates': 'Ir a plantillas',
      Actives: 'Activos',
      Inactives: 'Inactivos',
      'Activate user': 'Activar usuario',
      'Activate user help': '¿Está seguro que desea activar este usuario?',
      'Deactivate user': 'Desactivar usuario',
      'Cancel invitation': 'Cancelar invitación',
      'Deactivate user help': '¿Está seguro que desea desactivar este usuario?',
      'Cancel invitation help': '¿Está seguro que desea cancelar esta invitación?',
      'Edit user': 'Editar usuario',
      'Edit users': 'Editar usuario',
      'Delete user': 'Eliminar usuario',
      'Delete user help': '¿Está seguro que desea eliminar este usuario?',
      'Invitation pending': 'Invitación pendiente',
      After: 'Despues',
      'without answer': 'sin respuesta',
      'We are sorry': '¡Lo sentimos!',
      'No available chat': 'Pronto podrás comunicarte por correo desde Uniboxi.',
      Oops: 'Oops!',
      'We cant find the page you were looking for':
        'No pudimos encontrar la pagina que estás buscando.',
      'You can go to the': 'Puedes volver a la ',
      'last page': 'página anterior',
      'or direct to': 'o dirigirte al ',
      'main dashboard': 'dashboard principal.',
      'home screen': 'pantalla de inicio ',
      'or contact our': 'o contactarte con nuestro ',
      'help center': 'centro de ayuda ',
      'so we can solve your problem': 'para que pueda solucionar tu problema.',
      Hours: 'Hora(s)',
      Days: 'Día(s)',
      Weeks: 'Semana(s)',
      'Restarts in': 'Reinicia en',
      days: 'días',
      Language: 'Lenguaje',
      'Change language': 'Cambiar idioma',
      unknown_first_contact: 'Desconocido',
      'Unknown name': 'Nombre desconocido',
      you: 'Tú',
      'Mark as read': 'Marcar como leído',
      Favourite: 'Favorito',
      'Mark as favourite': 'Marcar como favorito',
      'Unmark as favourite': 'Desmarcar como favorito',
      'Mark as unread': 'Marcar como no leído',
      rate_limit_exceded:
        'Tasa de mensajes excedida para este canal, intente enviar de nuevo mas tarde.',
      timeout_sending: 'Se ha tardado demasiado tiempo en recibir el mensaje, por favor revisa si el número está correcto.',
      invalid_number: 'Mensaje no enviado. Número de WhatsApp no existe.',
      invalid_number_help:
        'Por favor revisa si el número está correcto. En caso que esté incorrecto, por favor eliminar la tarjeta y crear una nueva para este contacto.',
      'Know more': 'Conocer más.',
      'More information': 'Más información',
      'Send invitation': 'Enviar invitación',
      'No users help': 'Agrega o elimina usuarios de Uniboxi.',
      'Search templates': 'Buscar plantillas',
      'Search sequences': 'Buscar secuencias',
      See: 'Ver',
      All: 'Todos',
      Everybody: 'Todos',
      item: 'Item(s)',
      'Created by me': 'Creados por mi',
      Delete_sequence_help: '¿Está seguro que desea eliminar esta secuencia?',
      Delete_template_help: '¿Está seguro que desea eliminar esta plantilla?',
      Delete_automation_help: '¿Está seguro que desea eliminar este mensaje?',
      Contact_row_moved: 'Se reubicará en orden cronológico.',
      Conversations: 'Conversaciones',
      Active: 'Activo',
      Inactive: 'Inactivo',
      'Drag the categories to the position you want':
        'Arrastre las categorías a la posición que desee.',
      'Change order of categories': 'Cambiar orden de categorías',
      Information24h:
        'Al crear una secuencia, tienes una ventana de 24 horas para enviar mensajes automatizados. Después de 24 horas del último mensaje del cliente, no te puedes comunicar con esta persona hasta que te escriba de nuevo (De acuerdo con las políticas de Facebook Messenger y Whatsapp).',
      'Unlink channel': 'Cuenta desvinculada',
      'Your channel': 'Su cuenta',
      'has disengaged. Click on the': 'se ha desvinculado. Haga click en el',
      'next link': 'siguiente enlace',
      'to re-link': 'para volver a vincular.',
      'Authentication failed. User not found.': 'Falló la autenticación. Usuario no encontrado.',
      'Authentication failed. Wrong password.': 'Falló la autenticación. Contraseña incorrecta.',
      'The company isnt confirmed yet.': 'La empresa aún no está confirmada.',
      Search: 'Buscar',
      'Export in .csv format': 'Exportar en formato .csv',
      'Export in .xlsx format': 'Exportar en formato .xlsx',
      'Save contact': 'Guardar contacto',
      'Export dashboard': 'Exportar tablero',
      'Select one of the following options to download your dashboard':
        'Seleccione una de las siguientes opciones para descargar su tablero.',
      'Whole board': 'Todo el tablero',
      'Time frame': 'Período',
      export_help:
        'Le enviaremos un correo electrónico a {{email}} con el link para descargar el archivo en formato {{type}}',
      'Export board': 'Exportar tablero',
      Start: 'Comienzo',
      End: 'Finalización',
      Previous: 'Anterior',
      Next: 'Siguiente',
      'Send to': 'Para',
      'Message for': 'Mensaje para:',
      'Select the paid template you want to send':
        'Seleccione la plantilla paga que desea enviar',
      'Sending from': 'Desde',
      'Add Information (Optional)': 'Agregar Información (Opcional)',
      NewWhatsappMessageHelp:
        'WhatsApp sólo permite usar plantillas preaprobadas para contactar a una persona por primera vez.',
      NewWhatsappNoTemplates:
        'WhatsApp sólo permite usar plantillas pre-aprobadas para contactar a una persona por primera vez. Para agregar su primer plantilla de pago, haga click a continuación.',
      NewWhatsappPlanUpgrade:
        'Funcionalidad exclusiva Plan Pro. Haga click a continuación para mejorar su cuenta.',
      'Go to plans': 'Ir a planes',
      'Complete the following fields with the information you want':
      'Complete los siguientes campos con la información que desee.',
      'View inactive': 'Ver inactivos',
      NoIntegratedChannelsTitle: 'Ten mejores conversaciones con tus clientes usando mensajería.',
      NoIntegratedChannelsList1: 'Un tablero simple para ver en un solo lugar todos los mensajes de WhatsApp, Instagram y Facebook Messenger.',
      NoIntegratedChannelsList2: 'Agrega todo tu equipo de ventas o soporte y divide los mensajes entrantes entre ellos. Luego monitorea fácilmente las conversaciones de tu equipo.',
      NoIntegratedChannelsList3: 'Comienza integrando un número de WhatsApp, cuenta de Instagram o Messenger.',
      'Start by integrating a WhatsApp number, Instagram or Messenger account.': 'Comienza integrando un número de WhatsApp, cuenta de Instagram o Messenger.',
      'Integrate a channel': 'Vincular un canal',
      'Transfer conversations in bulk': 'Transferir conversaciones masivamente',
      'Are you sure you want to transfer all': '¿Seguro que desea transferir las',
      conversations: 'conversaciones',
      from: 'de',
      to: 'a',
      'Go back': 'Volver',
      'Choose the user who currently owns the conversations.': 'Elija al usuario propietario de las conversaciones actualmente.',
      'What categories of conversations do you want to transfer?': '¿Qué categorías de conversaciones desea transferir?',
      'Choose the user you\'ll transfer the conversations to.': 'Elija al usuario a quien transferir las conversaciones.',
      Continue: 'Continuar',
      'Transfer completed': 'Transferencia completada',
      'The transfer was completed successfully.': 'La transferencia fue completada con éxito.',
      'Active users': 'Usuarios activos',
      'Inactive users': 'Usuarios inactivos',
      'Select a user': 'Selecciona un usuario',
      'Search in': 'Buscar en',
      'Drop your files here': 'Suelta tus archivos aquí',
      'Copy image': 'Copiar imagen',
      'Download image': 'Descargar imagen',
      Status: 'Estado',
      Categories: 'Categorías',
      'Delete batch_send': 'Eliminar envío masivo',
      Delete_batch_send_help1: '¿Está seguro que desea eliminar este envío masivo?',
      cant_deactivate_with_clients_assigned: 'No es posible desactivar usuarios que tengan conversaciones asignadas. Por favor transferir todas sus conversaciones a otro usuario e intentar nuevamente.',
      'Transfer conversations': 'Transferir conversaciones',
      'This contact is already in Uniboxi': 'Este contacto ya está en Uniboxi',
      newWhatsappMessageClientCardDescription: 'Se ha contactado a {{firstName}} en {{count}} tarjetas diferentes. Puedes hacer click en las tarjetas y continuar la conversación, o seguir adelante y escribirle desde el nuevo número de WhatsApp seleccionado.',
      newWhatsappMessageClientCardDescriptionSameChannel: 'Se ha contactado a {{firstName}} en {{count}} tarjetas diferentes. Puedes hacer click en las tarjetas y continuar la conversación, la primera tarjeta corresponde al mismo canal.',
      client: 'cliente',
      ...esIntegrations,
      ...esLogin,
      ...esTopbar,
      ...esDrawer,
      ...esAccount,
      ...esEllipsisPopover,
      ...esTemplates,
      ...esSequences,
      ...esRules,
      ...esChat,
      ...esFilter,
      ...esDashboard,
      ...esNotifications,
      ...esAutomation,
      ...esReports,
      ...esTimezones,
      ...esTools,
    },
  },
};
